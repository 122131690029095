import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ブランドタイポグラフィ - タイポグラフィ" mdxType="SEO" />
    <PageTitle title="ブランドタイポグラフィ" enTitle="Brand Typography" mdxType="PageTitle" />
    <p>{`Amebaらしさと、読みやすさ、認識しやすさを意識した書体として、Ameba Sans、UD新丸ゴ、AXISを利用します。`}</p>
    <p>{`ブランドタイポグラフィとして定義しているこれらのフォントは原則WebやアプリのUIで利用することはありません。
ただし、Ameba Sansに関しては、ダイナミックな値の変更のため、数字のみをサブセット化してWebやアプリで利用する場合があります。`}</p>
    <h2 {...{
      "id": "ameba-sans",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ameba-sans",
        "aria-label": "ameba sans permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Ameba Sans`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a7e2829b9db1c6b830603a9a82f6c064/5c744/typography-ameba-sans.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a7e2829b9db1c6b830603a9a82f6c064/2aaaf/typography-ameba-sans.webp 160w", "/static/a7e2829b9db1c6b830603a9a82f6c064/85e47/typography-ameba-sans.webp 320w", "/static/a7e2829b9db1c6b830603a9a82f6c064/75198/typography-ameba-sans.webp 640w", "/static/a7e2829b9db1c6b830603a9a82f6c064/691bc/typography-ameba-sans.webp 960w", "/static/a7e2829b9db1c6b830603a9a82f6c064/40592/typography-ameba-sans.webp 1206w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a7e2829b9db1c6b830603a9a82f6c064/69538/typography-ameba-sans.png 160w", "/static/a7e2829b9db1c6b830603a9a82f6c064/72799/typography-ameba-sans.png 320w", "/static/a7e2829b9db1c6b830603a9a82f6c064/6af66/typography-ameba-sans.png 640w", "/static/a7e2829b9db1c6b830603a9a82f6c064/d9199/typography-ameba-sans.png 960w", "/static/a7e2829b9db1c6b830603a9a82f6c064/5c744/typography-ameba-sans.png 1206w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a7e2829b9db1c6b830603a9a82f6c064/6af66/typography-ameba-sans.png",
              "alt": "Ameba Sans",
              "title": "Ameba Sans",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`「Ameba Sans」は、CyberAgentのコーポレートフォントです。`}</p>
    <p>{`例: キャッチコピーやタイトル、など。`}</p>
    <h2 {...{
      "id": "ud新丸ゴ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ud%E6%96%B0%E4%B8%B8%E3%82%B4",
        "aria-label": "ud新丸ゴ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`UD新丸ゴ`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3db9cd17a3b2a14d2893f7a6df0b075b/061c7/typography-ud-shinmaru-go.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.12499999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3db9cd17a3b2a14d2893f7a6df0b075b/2aaaf/typography-ud-shinmaru-go.webp 160w", "/static/3db9cd17a3b2a14d2893f7a6df0b075b/85e47/typography-ud-shinmaru-go.webp 320w", "/static/3db9cd17a3b2a14d2893f7a6df0b075b/75198/typography-ud-shinmaru-go.webp 640w", "/static/3db9cd17a3b2a14d2893f7a6df0b075b/691bc/typography-ud-shinmaru-go.webp 960w", "/static/3db9cd17a3b2a14d2893f7a6df0b075b/2f82f/typography-ud-shinmaru-go.webp 1216w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3db9cd17a3b2a14d2893f7a6df0b075b/69538/typography-ud-shinmaru-go.png 160w", "/static/3db9cd17a3b2a14d2893f7a6df0b075b/72799/typography-ud-shinmaru-go.png 320w", "/static/3db9cd17a3b2a14d2893f7a6df0b075b/6af66/typography-ud-shinmaru-go.png 640w", "/static/3db9cd17a3b2a14d2893f7a6df0b075b/d9199/typography-ud-shinmaru-go.png 960w", "/static/3db9cd17a3b2a14d2893f7a6df0b075b/061c7/typography-ud-shinmaru-go.png 1216w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3db9cd17a3b2a14d2893f7a6df0b075b/6af66/typography-ud-shinmaru-go.png",
              "alt": "UD新丸ゴ",
              "title": "UD新丸ゴ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Ameba Sansにも通じるAmebaらしい丸い字体が「親しみやすさ」持ち、またユニバーサルデザイン書体としての「読みやすさ」があります。`}</p>
    <p>{`例：グラフィック作成時のタイトル、キャッチコピー、など。`}</p>
    <h2 {...{
      "id": "axis",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#axis",
        "aria-label": "axis permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`AXIS`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/93b474f5b9b370eb890d6f79b468492d/061c7/typography-axis.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.12499999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/93b474f5b9b370eb890d6f79b468492d/2aaaf/typography-axis.webp 160w", "/static/93b474f5b9b370eb890d6f79b468492d/85e47/typography-axis.webp 320w", "/static/93b474f5b9b370eb890d6f79b468492d/75198/typography-axis.webp 640w", "/static/93b474f5b9b370eb890d6f79b468492d/691bc/typography-axis.webp 960w", "/static/93b474f5b9b370eb890d6f79b468492d/2f82f/typography-axis.webp 1216w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/93b474f5b9b370eb890d6f79b468492d/69538/typography-axis.png 160w", "/static/93b474f5b9b370eb890d6f79b468492d/72799/typography-axis.png 320w", "/static/93b474f5b9b370eb890d6f79b468492d/6af66/typography-axis.png 640w", "/static/93b474f5b9b370eb890d6f79b468492d/d9199/typography-axis.png 960w", "/static/93b474f5b9b370eb890d6f79b468492d/061c7/typography-axis.png 1216w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/93b474f5b9b370eb890d6f79b468492d/6af66/typography-axis.png",
              "alt": "AXIS",
              "title": "AXIS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`例: 長い本文、など。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      